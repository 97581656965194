<template>
  <v-range-slider
    v-model="dateRange"
    :max="maxDate"
    :min="minDate"
    hide-details
    class="align-center mt-4"
    @change="filter"
  >
    <template v-slot:prepend>
      <div style="white-space: nowrap" class="text-caption">
        {{ dates[dateRange[0]] | formatDateYear }}
      </div>
    </template>
    <template v-slot:append>
      <div style="white-space: nowrap" class="text-caption">
        {{ dates[dateRange[1] - 1] | formatDateYear }}
      </div>
    </template>
  </v-range-slider>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {
      type: Object,
      required: false,
    },
    minDate: {
      type: [Date, String],
      required: true,
    },
    maxDate: {
      type: [Date, String],
      required: true,
    },
    unit: {
      type: String, // Options are "minutes", "hours", "days" and "months"
      required: false,
      default: "days",
    },
  },

  created() {
    const dates = this.enumerateBetweenDates(this.minDate, this.maxDate, this.unit);
    this.dateRange = [0, dates.length];
    this.minDate = 0;
    this.maxDate = this.dates.length;
  },
  methods: {
    enumerateBetweenDates(startDate, endDate, unit) {
      var dates = [];

      var currDate = moment(startDate).startOf("day");
      var lastDate = moment(endDate).startOf("day");

      while (currDate.add(1, unit).diff(lastDate) < 0) {
        console.log(currDate.toDate());
        dates.push(currDate.clone().toDate());
      }

      return dates;
    },
  },
};
</script>

<style></style>
